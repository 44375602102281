/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum PaymentStatus {
  Open = 'open',
  Canceled = 'canceled',
  Pending = 'pending',
  Authorized = 'authorized',
  Expired = 'expired',
  Failed = 'failed',
  Paid = 'paid',
}

export interface Payment {
  discountCode?: string;
  courseId?: string;
  whatsappCreditsOptionId?: string;
  subscriptionOptionExtrasIds?: string[];
  subscriptionOptionId?: string;
  invoiceId?: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  paidAt?: string;
  userId?: string;
  /** @format double */
  amount: number;
  name: string;
  status: PaymentStatus;
  paymentId: string;
}

export interface WhatsappCreditsPaymentCreateResponse {
  checkoutUrl: string;
  payment: Payment;
}

export interface WhatsappCreditsPaymentCreateRequest {
  whatsappCreditsOptionId: string;
}

/** Make all properties in T optional */
export interface PartialRecordSupportedLanguageString {
  en?: string;
  nl?: string;
}

export interface WhatsappCreditsOption {
  /** @format double */
  count: number;
  /** @format double */
  cost: number;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  id: string;
}

export interface Course {
  /** @format double */
  order: number;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  id: string;
}

export interface CourseCreateRequest {
  /** @format double */
  order: number;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
}

export interface ReorderRequest {
  elements: {
    /** @format double */
    order: number;
    id: string;
  }[];
}

export type CourseUpdateRequest = CourseCreateRequest;

export enum ModuleSegmentType {
  Url = 'url',
  Text = 'text',
}

export interface ModuleSegment {
  /** Make all properties in T optional */
  text?: PartialRecordSupportedLanguageString;
  url?: string;
  type: ModuleSegmentType;
  /** @format double */
  order: number;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  id: string;
}

export interface Module {
  segments: ModuleSegment[];
  courseId: string;
  /** @format double */
  order: number;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  id: string;
}

export interface ModuleCreateRequest {
  segments: ModuleSegment[];
  courseId: string;
  /** @format double */
  order: number;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
}

export interface ModuleUpdateRequest {
  segments: ModuleSegment[];
  courseId: string;
  /** @format double */
  order: number;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
}

export interface UploadedItem {
  url: string;
}

/** Construct a type with a set of properties K of type T */
export type RecordStringStringArray = Record<string, string[]>;

export interface ImagesCategoriesTree {
  Advice: string[];
  SkinGroups: string[];
  /** Construct a type with a set of properties K of type T */
  SkinDragAndDrop: RecordStringStringArray;
  /** Construct a type with a set of properties K of type T */
  Reports: RecordStringStringArray;
  /** Construct a type with a set of properties K of type T */
  Wishes: RecordStringStringArray;
  /** Construct a type with a set of properties K of type T */
  Vitality: RecordStringStringArray;
  /** Construct a type with a set of properties K of type T */
  Skin: RecordStringStringArray;
}

export interface Subscription {
  /** @format double */
  employeesCount: number;
  /** @format double */
  salonsCount: number;
  /** @format double */
  customersCount: number;
  payments: Payment[];
  startedBy?: string;
  /** @format date-time */
  validTo?: string;
  /** @format date-time */
  startedAt?: string;
  effectiveLimits: {
    /** @format double */
    vitality: number;
    /** @format double */
    skin: number;
    /** @format double */
    fingerprint: number;
    /** @format double */
    advice: number;
    /** @format double */
    wishes: number;
    /** @format double */
    salon: number;
    /** @format double */
    employee: number;
    /** @format double */
    customer: number;
  };
  subscriptionOptionExtrasIds: string[];
  subscriptionOptionId?: string;
  id: string;
}

export interface SubscriptionNextPayment {
  nextPaymentOptionExtrasIds: string[];
  nextPaymentOptionId: string;
  nextPaymentAmount: string;
  /** @format date-time */
  nextPaymentDate: string;
}

export interface SubscriptionOptionPaymentAndSubscriptionCreateResponse {
  checkoutUrl: string;
  payment: Payment;
}

export interface SubscriptionOptionPaymentAndSubscriptionCreateRequest {
  skipEmail?: boolean;
  discountCode?: string;
  autoExtend: boolean;
  redirectUrl?: string;
  subscriptionOptionExtrasIds: string[];
  subscriptionOptionId: string;
}

/** Object containing file metadata and access information. */
export interface ExpressMulterFile {
  /** Name of the form field associated with this file. */
  fieldname: string;
  /** Name of the file on the uploader's computer. */
  originalname: string;
  /**
   * Value of the `Content-Transfer-Encoding` header for this file.
   * @deprecated
   */
  encoding: string;
  /** Value of the `Content-Type` header for this file. */
  mimetype: string;
  /**
   * Size of the file in bytes.
   * @format double
   */
  size: number;
  /**
   * A readable stream of this file. Only available to the `_handleFile`
   * callback for custom `StorageEngine`s.
   * @format byte
   */
  stream: string;
  /** `DiskStorage` only: Directory to which this file has been uploaded. */
  destination: string;
  /** `DiskStorage` only: Name of this file within `destination`. */
  filename: string;
  /** `DiskStorage` only: Full path to the uploaded file. */
  path: string;
  /**
   * `MemoryStorage` only: A Buffer containing the entire file.
   * @format byte
   */
  buffer: string;
}

export enum DiscountType {
  Percent = 'percent',
  Fixed = 'fixed',
}

export interface DiscountConfig {
  /** @format double */
  value: number;
  type: DiscountType;
}

export interface DiscountCode {
  code: string;
  name: string;
  discount: DiscountConfig;
}

export type IRecordOfAny = Record<string, any>;

export enum SubscriptionOptionFeature {
  Customer = 'customer',
  Employee = 'employee',
  Salon = 'salon',
  Wishes = 'wishes',
  Skin = 'skin',
  Vitality = 'vitality',
  Advice = 'advice',
  Fingerprint = 'fingerprint',
}

export interface FeatureExtra {
  id: string;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  /** @format double */
  cost: number;
  features: {
    /** @format double */
    value: number;
    feature: SubscriptionOptionFeature;
  }[];
}

export interface SubscriptionOption {
  groups: string[];
  isHidden?: boolean;
  isTrial?: boolean;
  isHighlighted: boolean;
  /** @format double */
  order: number;
  featureExtras: FeatureExtra[];
  duration: {
    /** @format double */
    years: number;
    /** @format double */
    months: number;
    /** @format double */
    days: number;
  };
  features: {
    /** @format double */
    value: number;
    feature: SubscriptionOptionFeature;
  }[];
  /** Make all properties in T optional */
  description2: PartialRecordSupportedLanguageString;
  /** Make all properties in T optional */
  description: PartialRecordSupportedLanguageString;
  /** @format double */
  cost: number;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  id: string;
}

export interface Salon {
  id: string;
  name: string;
  description?: string;
  /** @format double */
  customersCount: number;
  /** @format double */
  employeesCount: number;
  /** @format date-time */
  deletedAt?: string;
  deletedBy?: string;
}

export interface SalonCreateRequest {
  name: string;
  description?: string;
}

export interface SalonUpdateRequest {
  name: string;
  description?: string;
  deleted: boolean;
}

export enum QuestionnaireType {
  CustomerQuestionnaire = 'customerQuestionnaire',
  EmployeeQuestionnaire = 'employeeQuestionnaire',
}

export interface QuestionRenderLogic {
  value: string;
  questionId: string;
}

export interface QuestionRenderLogicConfiguration {
  display: QuestionRenderLogic[];
}

export interface QuestionConfigurationBase {
  renderLogic?: QuestionRenderLogicConfiguration;
  isRequired?: boolean;
}

export enum QuestionTypeString {
  String = 'string',
}

export type StringQuestionConfiguration = QuestionConfigurationBase & {
  type: QuestionTypeString;
};

export enum QuestionTypeOption {
  Option = 'option',
}

export interface QuestionOption {
  id: string;
  /** Make all properties in T optional */
  customerTitle: PartialRecordSupportedLanguageString;
  /** Make all properties in T optional */
  employeeTitle: PartialRecordSupportedLanguageString;
  isHidden: boolean;
}

export type OptionsQuestionConfiguration = QuestionConfigurationBase & {
  allowMultipleValues?: boolean;
  allowCustomValue?: boolean;
  options: QuestionOption[];
  type: QuestionTypeOption;
};

export enum QuestionTypeDate {
  Date = 'date',
}

export type DateQuestionConfiguration = QuestionConfigurationBase & {
  type: QuestionTypeDate;
};

export enum QuestionTypeImage {
  Image = 'image',
}

export interface ImageQuestionOption {
  id: string;
  image: string;
  /** Make all properties in T optional */
  customerTitle: PartialRecordSupportedLanguageString;
  /** Make all properties in T optional */
  employeeTitle: PartialRecordSupportedLanguageString;
}

export type ImageQuestionConfiguration = QuestionConfigurationBase & {
  allowMultipleValues?: boolean;
  options: ImageQuestionOption[];
  type: QuestionTypeImage;
};

export enum QuestionTypeRating {
  Rating = 'rating',
}

export type RatingQuestionConfiguration = QuestionConfigurationBase & {
  type: QuestionTypeRating;
};

export enum QuestionTypeBoolean {
  Boolean = 'boolean',
}

export type BooleanQuestionConfiguration = QuestionConfigurationBase & {
  type: QuestionTypeBoolean;
};

export enum QuestionTypeInformation {
  Information = 'information',
}

export type InformationQuestionConfiguration = QuestionConfigurationBase & {
  video?: string;
  image?: string;
  /** Make all properties in T optional */
  body: PartialRecordSupportedLanguageString;
  type: QuestionTypeInformation;
};

export type QuestionConfiguration =
  | StringQuestionConfiguration
  | OptionsQuestionConfiguration
  | DateQuestionConfiguration
  | ImageQuestionConfiguration
  | RatingQuestionConfiguration
  | BooleanQuestionConfiguration
  | InformationQuestionConfiguration;

export interface Question {
  id: string;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  /** @format double */
  sortOrder: number;
  configuration: QuestionConfiguration;
  questionnaireId: string;
}

export interface Questionnaire {
  id: string;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  type: QuestionnaireType;
  salonId: string;
  /** Make all properties in T optional */
  description?: PartialRecordSupportedLanguageString;
  questions: Question[];
  messageTemplateId?: string;
  /** @format date-time */
  deletedAt?: string;
  deletedBy?: string;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickQuestionnaireExcludeKeysIdOrQuestionsOrSalonIdOrDeletedAtOrDeletedBy {
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  type: QuestionnaireType;
  /** Make all properties in T optional */
  description?: PartialRecordSupportedLanguageString;
  messageTemplateId?: string;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitQuestionnaireIdOrQuestionsOrSalonIdOrDeletedAtOrDeletedBy =
  PickQuestionnaireExcludeKeysIdOrQuestionsOrSalonIdOrDeletedAtOrDeletedBy;

/** From T, pick a set of properties whose keys are in the union K */
export interface PickQuestionExcludeKeysQuestionnaireIdOrConfiguration {
  id: string;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  /** @format double */
  sortOrder: number;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitQuestionQuestionnaireIdOrConfiguration = PickQuestionExcludeKeysQuestionnaireIdOrConfiguration;

export enum QuestionType {
  String = 'string',
  Option = 'option',
  Number = 'number',
  Boolean = 'boolean',
  Date = 'date',
  Rating = 'rating',
  Image = 'image',
  Information = 'information',
}

export interface QuestionOptionCreateType {
  isHidden: boolean;
  /** Make all properties in T optional */
  employeeTitle: PartialRecordSupportedLanguageString;
  /** Make all properties in T optional */
  customerTitle: PartialRecordSupportedLanguageString;
  image?: string;
  id: string;
}

export interface QuestionConfigurationCreateType {
  video?: string;
  image?: string;
  /** Make all properties in T optional */
  body?: PartialRecordSupportedLanguageString;
  options?: QuestionOptionCreateType[];
  type: QuestionType;
  renderLogic?: QuestionRenderLogicConfiguration;
  isHidden: boolean;
  isRequired: boolean;
  allowMultipleValues?: boolean;
  allowCustomValue?: boolean;
}

export type QuestionCreateType = OmitQuestionQuestionnaireIdOrConfiguration & {
  configuration: QuestionConfigurationCreateType;
};

export type QuestionnaireCreateRequest = OmitQuestionnaireIdOrQuestionsOrSalonIdOrDeletedAtOrDeletedBy & {
  questions: QuestionCreateType[];
};

/** From T, pick a set of properties whose keys are in the union K */
export interface PickQuestionExcludeKeysIdOrQuestionnaireIdOrConfiguration {
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  /** @format double */
  sortOrder: number;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitQuestionIdOrQuestionnaireIdOrConfiguration = PickQuestionExcludeKeysIdOrQuestionnaireIdOrConfiguration;

export interface QuestionOptionUpdateType {
  isHidden: boolean;
  /** Make all properties in T optional */
  employeeTitle: PartialRecordSupportedLanguageString;
  /** Make all properties in T optional */
  customerTitle: PartialRecordSupportedLanguageString;
  image?: string;
  id: string;
}

export interface QuestionConfigurationUpdateType {
  video?: string;
  image?: string;
  /** Make all properties in T optional */
  body?: PartialRecordSupportedLanguageString;
  options?: QuestionOptionUpdateType[];
  type: QuestionType;
  renderLogic?: QuestionRenderLogicConfiguration;
  isHidden: boolean;
  isRequired: boolean;
  allowMultipleValues?: boolean;
  allowCustomValue?: boolean;
}

export type QuestionUpdateType = OmitQuestionIdOrQuestionnaireIdOrConfiguration & {
  configuration: QuestionConfigurationUpdateType;
  id: string;
};

export type QuestionnaireUpdateRequest = OmitQuestionnaireIdOrQuestionsOrSalonIdOrDeletedAtOrDeletedBy & {
  deleted: boolean;
  questions: (QuestionUpdateType | QuestionCreateType)[];
};

export interface QuestionSubmission {
  id: string;
  questionnaireSubmissionId: string;
  questionId: string;
  answer: string[];
}

export interface QuestionnaireSubmission {
  id: string;
  userId?: string;
  complete: boolean;
  /** @format date-time */
  startedAt: string;
  /** @format date-time */
  completedAt?: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  questionnaireId: string;
  questionSubmissions: QuestionSubmission[];
}

export enum OrganisationMessagingType {
  Email = 'email',
  Whatsapp = 'whatsapp',
}

export interface QuestionnaireSubmissionNotification {
  type: OrganisationMessagingType;
}

export interface QuestionnaireSubmissionRequest {
  userId: string;
  questionnaireId: string;
  notificationToSend?: QuestionnaireSubmissionNotification;
}

export enum SalonPermissionType {
  ReadCustomers = 'readCustomers',
  ManageCustomers = 'manageCustomers',
  ReadEmployees = 'readEmployees',
  ManageEmployees = 'manageEmployees',
  ReadQuestionnaires = 'readQuestionnaires',
  ManageQuestionnaires = 'manageQuestionnaires',
  ReadAdvice = 'readAdvice',
  ManageAdvice = 'manageAdvice',
}

export interface SalonRole {
  id: string;
  name: string;
  permissions: SalonPermissionType[];
  /** @format date-time */
  deletedAt?: string;
  deletedBy?: string;
}

export interface SalonRoleCreateRequest {
  name: string;
  permissions: SalonPermissionType[];
}

export type SalonRoleUpdateRequest = SalonRoleCreateRequest & {
  deleted: boolean;
};

export type PaidCourse = Course & {
  /** Make all properties in T optional */
  description: PartialRecordSupportedLanguageString;
  purchased?: boolean;
  /** @format double */
  cost: number;
};

export interface PaidCoursePaymentCreateResponse {
  checkoutUrl?: string;
  payment?: Payment;
}

export type GlobalPaidCourse = PaidCourse & {
  discountCodesIds: string[];
};

export type PaidCourseCreateRequest = CourseCreateRequest & {
  discountCodesIds: string[];
  /** Make all properties in T optional */
  description: PartialRecordSupportedLanguageString;
  /** @format double */
  cost: number;
};

export type PaidCourseUpdateRequest = CourseUpdateRequest & {
  discountCodesIds: string[];
  /** @format double */
  cost: number;
  /** Make all properties in T optional */
  description: PartialRecordSupportedLanguageString;
};

export enum Algorithm {
  SHA1 = 'SHA-1',
  SHA256 = 'SHA-256',
  SHA512 = 'SHA-512',
}

export interface Challenge {
  algorithm: Algorithm;
  challenge: string;
  /** @format double */
  maxnumber?: number;
  salt: string;
  signature: string;
}

export interface PublicOrganisation {
  id: string;
  name: string;
  slug: string;
}

export interface OrganisationCustomerSettings {
  showAdvice: boolean;
}

export enum SupportedLanguage {
  En = 'en',
  Nl = 'nl',
}

export interface CustomOrganisationField {
  displayName: string;
  propertyName: string;
}

export interface Organisation {
  id: string;
  companyNameToUse: string;
  legalCompanyName: string;
  slug: string;
  address: string;
  address2?: string;
  postalCode: string;
  city: string;
  country: string;
  vatNumber?: string;
  contactEmployeeId: string;
  mollieSubscriptionId?: string;
  mollieCustomerId: string;
  invoiceLanguage: SupportedLanguage;
  customFields: CustomOrganisationField[];
  defaultCustomerMessagingType: OrganisationMessagingType;
  /** @format double */
  whatsappCredits: number;
  canModifyQuestionnaires: boolean;
  canModifyAdvice: boolean;
  /** @format date-time */
  deletedAt?: string;
  deletedBy?: string;
}

export enum UserTypeEmployee {
  Employee = 'employee',
}

export interface EmployeeSalonAccessPermissions {
  salonId: string;
  assignedSalonRoles: string[];
}

export enum OrganisationPermissionType {
  OwnOrganisation = 'ownOrganisation',
  ReadSubscription = 'readSubscription',
  ManageSubscription = 'manageSubscription',
  ReadSalons = 'readSalons',
  ManageSalons = 'manageSalons',
  ManagePermissions = 'managePermissions',
  ManageTrainings = 'manageTrainings',
  ManageDocuments = 'manageDocuments',
}

export interface EmployeeUser {
  organisationPermissions: OrganisationPermissionType[];
  salonsAccess: EmployeeSalonAccessPermissions[];
  language: SupportedLanguage;
  displayName?: string;
  deletedBy?: string;
  /** @format date-time */
  deletedAt?: string;
  type: UserTypeEmployee;
  email: string;
  organisationId: string;
  id: string;
}

export interface OrganisationCreateRequest {
  legalCompanyName: string;
  companyNameToUse: string;
  slug: string;
  vatNumber?: string;
  country: string;
  address: string;
  address2?: string;
  postalCode: string;
  city: string;
  invoiceLanguage: SupportedLanguage;
  email: string;
  password: string;
  challengePayload: string;
}

export interface OrganisationUpdateRequest {
  legalCompanyName: string;
  companyNameToUse: string;
  slug: string;
  vatNumber?: string;
  country: string;
  address: string;
  address2?: string;
  postalCode: string;
  city: string;
  contactEmployeeId: string;
  invoiceLanguage: SupportedLanguage;
  customFields: CustomOrganisationField[];
  defaultCustomerMessagingType: OrganisationMessagingType;
}

export interface GlobalAdminUpdateOrganisationRequest {
  canModifyAdvice: boolean;
  canModifyQuestionnaires: boolean;
  legalCompanyName: string;
  companyNameToUse: string;
  organisationId: string;
}

export interface GlobalDiscountCode {
  id: string;
  code: string;
  name: string;
  /** @format date-time */
  validFrom: string;
  /** @format date-time */
  validTo: string;
  discount: DiscountConfig;
}

export enum MessageType {
  LoginRequest = 'loginRequest',
  NewQuestionnaire = 'newQuestionnaire',
  NewEmployee = 'newEmployee',
  ResetPassword = 'resetPassword',
  SubscriptionUpdate = 'subscriptionUpdate',
  SubscriptionCancel = 'subscriptionCancel',
  RecurringPayment = 'recurringPayment',
  WhatsappCreditsPurchase = 'whatsappCreditsPurchase',
  PaidCoursePurchase = 'paidCoursePurchase',
  NewCustomerConsentForm = 'newCustomerConsentForm',
  NewCustomerDocument = 'newCustomerDocument',
  NewOrganisation = 'newOrganisation',
  NewInternalOrganisation = 'newInternalOrganisation',
}

export interface EmailTemplate {
  filename: string;
  /** Make all properties in T optional */
  subject: PartialRecordSupportedLanguageString;
}

export interface MessageTemplate {
  emailTemplate: EmailTemplate;
  whatsAppTemplateName?: string;
  type: MessageType;
  name: string;
  id: string;
}

export interface SkinAnalysisIcon {
  id: string;
  iconName: string;
  groupName: string;
  customLabel?: string;
  /** @format double */
  x: number;
  /** @format double */
  y: number;
}

export interface SkinAnalysis {
  id: string;
  icons: SkinAnalysisIcon[];
  salonId: string;
  userId: string;
}

export interface SkinAnalysisUpdateRequest {
  icons: SkinAnalysisIcon[];
}

export enum EmployeeActivityType {
  Login = 'login',
  FailedLogin = 'failedLogin',
  PasswordResetRequest = 'passwordResetRequest',
  SuccessfulPasswordReset = 'successfulPasswordReset',
  CustomerCommentAdd = 'customerCommentAdd',
  CustomerCommentUpdate = 'customerCommentUpdate',
  CustomerCommentDelete = 'customerCommentDelete',
  CustomerCreate = 'customerCreate',
  CustomerUpdate = 'customerUpdate',
  CustomersSearch = 'customersSearch',
  CustomersImport = 'customersImport',
  CustomerHardDelete = 'customerHardDelete',
  DashboardConfigurationUpdate = 'dashboardConfigurationUpdate',
  EmployeeCreate = 'employeeCreate',
  EmployeeUpdate = 'employeeUpdate',
  CustomerSkinAnalysisUpdate = 'customerSkinAnalysisUpdate',
  OrganisationUpdate = 'organisationUpdate',
  CreateRole = 'createRole',
  UpdateRole = 'updateRole',
  QuestionnaireCreate = 'questionnaireCreate',
  QuestionnaireUpdate = 'questionnaireUpdate',
  SalonCreate = 'salonCreate',
  SalonUpdate = 'salonUpdate',
  CreatePayment = 'createPayment',
  WhatsappCreditsPaymentCreate = 'whatsappCreditsPaymentCreate',
  PaidCousePaymentCreate = 'paidCousePaymentCreate',
  CancelSubscription = 'cancelSubscription',
  UpdateSubscription = 'updateSubscription',
  CustomerConsentSend = 'customerConsentSend',
  CustomerDocumentSend = 'customerDocumentSend',
  CustomerDocumentDelete = 'customerDocumentDelete',
}

export interface EmployeeActivity {
  /** @format date-time */
  createdAt: string;
  data: any;
  type: EmployeeActivityType;
  userId?: string;
  id: string;
}

export interface EmployeeActivitySearchRequest {
  /** @format double */
  limit?: number;
  /** @format double */
  skip?: number;
  /** @format date-time */
  endDate?: string;
  /** @format date-time */
  startDate?: string;
  types?: EmployeeActivityType[];
  employeeIds?: string[];
}

export type EffectivePermissions = Record<string, (SalonPermissionType | OrganisationPermissionType)[]>;

export interface EmployeeCreateRequest {
  email: string;
  displayName?: string;
  language: SupportedLanguage;
  salonsAccess: EmployeeSalonAccessPermissions[];
  organisationPermissions?: OrganisationPermissionType[];
}

export interface EmployeeEditRequest {
  displayName?: string;
  language: SupportedLanguage;
  deleted: boolean;
  newPassword?: string;
  currentPassword?: string;
  salonsAccess: EmployeeSalonAccessPermissions[];
  organisationPermissions?: OrganisationPermissionType[];
}

export enum DocumentType {
  Document = 'document',
  ConsentForm = 'consentForm',
}

export interface Document {
  deletedBy?: string;
  /** @format date-time */
  deletedAt?: string;
  type: DocumentType;
  /** Make all properties in T optional */
  text: PartialRecordSupportedLanguageString;
  /** Make all properties in T optional */
  description?: PartialRecordSupportedLanguageString;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  id: string;
}

export interface DocumentCreateRequest {
  type: DocumentType;
  /** Make all properties in T optional */
  text: PartialRecordSupportedLanguageString;
  /** Make all properties in T optional */
  description?: PartialRecordSupportedLanguageString;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
}

export interface DocumentUpdateRequest {
  deleted: boolean;
  /** Make all properties in T optional */
  text?: PartialRecordSupportedLanguageString;
  /** Make all properties in T optional */
  description?: PartialRecordSupportedLanguageString;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
}

export enum QuestionnaireDashboardTypeWishes {
  Wishes = 'Wishes',
}

export enum MappingElementLabelWidth {
  Default = 'Default',
  Short = 'Short',
  Long = 'Long',
  Max = 'Max',
}

export interface BaseMappingElement {
  labelWidth?: MappingElementLabelWidth;
  labelPostfix?: string;
  labelPosition: string;
  image?: string;
  questionId?: string;
  questionnaireId?: string;
  elementName: string;
  groupName: string;
}

export enum MappingElementTypeConditional {
  Conditional = 'Conditional',
}

export enum ConditionValueOption {
  ValueAnyText = '<any_text>',
}

export type ConditionalMappingElement = BaseMappingElement & {
  value: string | ConditionValueOption;
  type: MappingElementTypeConditional;
};

export enum MappingElementTypeDirect {
  Direct = 'Direct',
}

export type DirectMappingElement = BaseMappingElement & {
  type: MappingElementTypeDirect;
};

export enum MappingElementTypePersistent {
  Persistent = 'Persistent',
}

export type PersistentMappingElement = BaseMappingElement & {
  type: MappingElementTypePersistent;
};

export type MappingElement = ConditionalMappingElement | DirectMappingElement | PersistentMappingElement;

export interface WishesDashboardConfiguration {
  elements: MappingElement[];
  type: QuestionnaireDashboardTypeWishes;
  id: string;
}

export enum QuestionnaireDashboardTypeWishes2 {
  Wishes2 = 'Wishes2',
}

export interface Wishes2DashboardConfiguration {
  elements: MappingElement[];
  type: QuestionnaireDashboardTypeWishes2;
  id: string;
}

export enum QuestionnaireDashboardTypeSkin {
  Skin = 'Skin',
}

export interface SkinDashboardConfiguration {
  elements: MappingElement[];
  type: QuestionnaireDashboardTypeSkin;
  id: string;
}

export enum QuestionnaireDashboardTypeVitality {
  Vitality = 'Vitality',
}

export interface VitalityDashboardConfiguration {
  elements: MappingElement[];
  type: QuestionnaireDashboardTypeVitality;
  id: string;
}

export type DashboardConfiguration =
  | WishesDashboardConfiguration
  | Wishes2DashboardConfiguration
  | SkinDashboardConfiguration
  | VitalityDashboardConfiguration;

export enum QuestionnaireDashboardType {
  Wishes = 'Wishes',
  Wishes2 = 'Wishes2',
  Skin = 'Skin',
  Vitality = 'Vitality',
}

export enum MappingElementType {
  Direct = 'Direct',
  Conditional = 'Conditional',
  Persistent = 'Persistent',
}

export interface MappingElementUpdateType {
  labelWidth?: MappingElementLabelWidth;
  labelPostfix?: string;
  labelPosition: string;
  image?: string;
  questionId: string;
  questionnaireId: string;
  groupName: string;
  elementName: string;
  value?: string;
  type: MappingElementType;
}

export interface DashboardConfigurationUpdateType {
  elements?: MappingElementUpdateType[];
  id?: string;
  type: QuestionnaireDashboardType;
}

export interface DashboardConfigurationsUpdateRequest {
  configurations: DashboardConfigurationUpdateType[];
}

export enum CustomerGender {
  Male = 'male',
  Female = 'female',
  Undefined = 'undefined',
}

export interface CustomerUserDetails {
  allowedMessagingTypes: OrganisationMessagingType[];
  messagingType?: OrganisationMessagingType;
  profileImage?: string;
  language: SupportedLanguage;
  notes?: string;
  gender?: CustomerGender;
  /** @format date-time */
  dateOfBirth?: string;
  city?: string;
  zipcode?: string;
  address?: string;
  phoneNumber2?: string;
  phoneNumber?: string;
}

/** Construct a type with a set of properties K of type T */
export type RecordStringUnknown = Record<string, any>;

export enum UserTypeCustomer {
  Customer = 'customer',
}

export interface CustomerUser {
  type: UserTypeCustomer;
  deletedBy?: string;
  /** @format date-time */
  deletedAt?: string;
  /** Construct a type with a set of properties K of type T */
  customFields: RecordStringUnknown;
  details: CustomerUserDetails;
  name: string;
  email: string;
  accessibleSalons: string[];
  organisationId: string;
  id: string;
}

export interface CustomerCreateRequest {
  email: string;
  name: string;
  salonIds: string[];
  details: CustomerUserDetails;
  customFields: any;
}

export interface CurrentCustomerUpdateRequest {
  name: string;
  details: CustomerUserDetails;
}

export enum CustomerConsentStatus {
  NotSeen = 'notSeen',
  Seen = 'seen',
  Accepted = 'accepted',
  Declined = 'declined',
}

export interface CurrentCustomerDocument {
  form: {
    /** Make all properties in T optional */
    text: PartialRecordSupportedLanguageString;
    /** Make all properties in T optional */
    name: PartialRecordSupportedLanguageString;
  };
  /** @format date-time */
  consentStatusUpdatedAt?: string;
  consentStatus?: CustomerConsentStatus;
  documentId: string;
  type: DocumentType;
  /** @format date-time */
  createdAt: string;
  id: string;
}

export enum CustomerActivityType {
  SubmissionCreated = 'submissionCreated',
  AnswerUpdated = 'answerUpdated',
  Login = 'login',
  FailedLogin = 'failedLogin',
  CodeRequest = 'codeRequest',
  ProfileUpdate = 'profileUpdate',
  DocumentStatusUpdated = 'documentStatusUpdated',
  DocumentSent = 'documentSent',
  DocumentDelete = 'documentDelete',
}

export enum UserType {
  Customer = 'customer',
  Employee = 'employee',
  GlobalAdmin = 'globalAdmin',
}

export enum QuestionnaireSubmissionCreationBasis {
  Email = 'email',
  WhatsApp = 'whatsApp',
  InApp = 'inApp',
}

export interface CustomerActivity {
  id: string;
  userId: string;
  data: {
    documentDelete?: {
      deletedBy: string;
      email: string;
      documentName: string;
      documentId: string;
    };
    documentSent?: {
      sentBy: string;
      email: string;
      documentName: string;
      documentId: string;
    };
    documentStatusUpdated?: {
      status: CustomerConsentStatus;
      documentName: string;
      documentId: string;
    };
    profileUpdate?: {
      updatedByUserType: UserType;
      updatedBy: string;
      data: {
        customFields: any;
        details: CustomerUserDetails;
        salonIds: string[];
        deleted: boolean;
        email: string;
        name: string;
      };
    };
    codeRequest?: {
      email: string;
    };
    failedLogin?: {
      email: string;
    };
    login?: {
      email: string;
    };
    submissionCreated?: {
      createdBy: string;
      targetPhoneNumber?: string;
      targetEmail?: string;
      creationBasis: QuestionnaireSubmissionCreationBasis;
      questionnaireSubmissionId: string;
    };
    answerUpdated?: {
      answeredByUserType: UserType;
      answeredBy: string;
      answer: string[];
      questionId: string;
      questionnaireSubmissionId: string;
    };
    activityType: CustomerActivityType;
  };
  /** @format date-time */
  createdAt: string;
}

export interface CustomerEditRequest {
  name: string;
  email: string;
  deleted: boolean;
  salonIds: string[];
  details: CustomerUserDetails;
  customFields: any;
}

export interface CustomerDocument {
  /** @format date-time */
  consentStatusUpdatedAt?: string;
  consentStatus?: CustomerConsentStatus;
  /** @format date-time */
  sentAt: string;
  sentBy: string;
  salonId: string;
  documentId: string;
  userId: string;
  id: string;
}

export interface SendCustomerDocumentRequest {
  notificationToSend: QuestionnaireSubmissionNotification;
  documentId: string;
}

export interface CustomersSearchRequest {
  salonIds: string[];
  containingText?: string;
  showDeleted: boolean;
}

export interface CustomersBulkCreateRequest {
  customers: CustomerCreateRequest[];
}

export enum CustomerCommentType {
  Treatment = 'Treatment',
  Products = 'Products',
  SkinPlan = 'SkinPlan',
  OnPoint = 'OnPoint',
}

export interface CustomerComment {
  /** @format date-time */
  deletedAt?: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  createdAt: string;
  createdBy: string;
  content: string;
  type: CustomerCommentType;
  id: string;
}

export interface CustomerCommentCreateRequest {
  content: string;
  type: CustomerCommentType;
}

export type CustomerCommentUpdateRequest = CustomerCommentCreateRequest;

export enum UserTypeGlobalAdmin {
  GlobalAdmin = 'globalAdmin',
}

export interface GlobalAdminJwtClaims {
  token: string;
  type: UserTypeGlobalAdmin;
  username: string;
  id: string;
}

export interface EmployeeJwtClaims {
  token: string;
  organisationId: string;
  type: UserTypeEmployee;
  email: string;
  id: string;
}

export interface ImpersonateEmployeeRequest {
  email: string;
  organisationId: string;
}

export interface CustomerJwtClaims {
  language: SupportedLanguage;
  token: string;
  organisationId: string;
  type: UserTypeCustomer;
  email: string;
  id: string;
}

export interface EmployeePasswordResetRequest {
  confirmationCode: string;
  userId: string;
  password: string;
}

export enum AdviceType {
  CustomerAdvice = 'customerAdvice',
  EmployeeAdvice = 'employeeAdvice',
}

export interface AdviceContent {
  id?: string;
  image: string;
  /** Make all properties in T optional */
  advice?: PartialRecordSupportedLanguageString;
  /** Make all properties in T optional */
  audioUrl?: PartialRecordSupportedLanguageString;
}

export interface Advice {
  id: string;
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  /** Make all properties in T optional */
  description?: PartialRecordSupportedLanguageString;
  type: AdviceType;
  salonId: string;
  adviceGroupId: string;
  adviceConditionsIds: string[];
  adviceContent: AdviceContent[];
  /** @format double */
  order: number;
  /** @format date-time */
  deletedAt?: string;
  deletedBy?: string;
}

export interface AdviceGroup {
  id: string;
  name: string;
  salonId: string;
}

export interface AdviceConditionOption {
  questionnaireId: string;
  questionId: string;
  value: string | ConditionValueOption;
}

export interface AdviceCondition {
  id: string;
  name: string;
  description?: string;
  salonId: string;
  conditions: AdviceConditionOption[];
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickAdviceExcludeKeysIdOrSalonIdOrDeletedAtOrDeletedByOrAdviceContent {
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  type: AdviceType;
  /** Make all properties in T optional */
  description?: PartialRecordSupportedLanguageString;
  adviceGroupId: string;
  adviceConditionsIds: string[];
  /** @format double */
  order: number;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitAdviceIdOrSalonIdOrDeletedAtOrDeletedByOrAdviceContent =
  PickAdviceExcludeKeysIdOrSalonIdOrDeletedAtOrDeletedByOrAdviceContent;

/** From T, pick a set of properties whose keys are in the union K */
export interface PickAdviceContentExcludeKeysId {
  image: string;
  /** Make all properties in T optional */
  advice?: PartialRecordSupportedLanguageString;
  /** Make all properties in T optional */
  audioUrl?: PartialRecordSupportedLanguageString;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitAdviceContentId = PickAdviceContentExcludeKeysId;

export type AdviceContentCreate = OmitAdviceContentId;

export type AdviceCreateRequest = OmitAdviceIdOrSalonIdOrDeletedAtOrDeletedByOrAdviceContent & {
  adviceContent: AdviceContentCreate[];
};

/** From T, pick a set of properties whose keys are in the union K */
export interface PickAdviceGroupExcludeKeysIdOrSalonId {
  name: string;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitAdviceGroupIdOrSalonId = PickAdviceGroupExcludeKeysIdOrSalonId;

export type AdviceGroupCreateRequest = OmitAdviceGroupIdOrSalonId;

/** From T, pick a set of properties whose keys are in the union K */
export interface PickAdviceConditionExcludeKeysIdOrSalonId {
  name: string;
  description?: string;
  conditions: AdviceConditionOption[];
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitAdviceConditionIdOrSalonId = PickAdviceConditionExcludeKeysIdOrSalonId;

export type AdviceConditionCreateRequest = OmitAdviceConditionIdOrSalonId;

/** From T, pick a set of properties whose keys are in the union K */
export interface PickAdviceExcludeKeysIdOrSalonIdOrDeletedAtOrDeletedBy {
  /** Make all properties in T optional */
  name: PartialRecordSupportedLanguageString;
  type: AdviceType;
  /** Make all properties in T optional */
  description?: PartialRecordSupportedLanguageString;
  adviceContent: AdviceContent[];
  adviceGroupId: string;
  adviceConditionsIds: string[];
  /** @format double */
  order: number;
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitAdviceIdOrSalonIdOrDeletedAtOrDeletedBy = PickAdviceExcludeKeysIdOrSalonIdOrDeletedAtOrDeletedBy;

export type AdviceUpdateRequest = OmitAdviceIdOrSalonIdOrDeletedAtOrDeletedBy & {
  deleted: boolean;
};

export type AdviceGroupUpdateRequest = OmitAdviceGroupIdOrSalonId;

export type AdviceConditionUpdateRequest = OmitAdviceConditionIdOrSalonId;

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '/' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title resense-core-api
 * @version 1.0.0
 * @license ISC
 * @baseUrl /
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  whatsappCredits = {
    /**
     * No description
     *
     * @tags Whatsapp credits
     * @name CreateWhatsappCreditsPayment
     * @request POST:/organisations/{organisationId}/whatsapp-credits/credit-payment
     * @secure
     */
    createWhatsappCreditsPayment: (
      organisationId: string,
      data: WhatsappCreditsPaymentCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<WhatsappCreditsPaymentCreateResponse, any>({
        path: `/organisations/${organisationId}/whatsapp-credits/credit-payment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Whatsapp credits
     * @name GetWhatsappCreditsOptions
     * @request GET:/organisations/{organisationId}/whatsapp-credits/options
     * @secure
     */
    getWhatsappCreditsOptions: (organisationId: string, params: RequestParams = {}) =>
      this.request<WhatsappCreditsOption[], any>({
        path: `/organisations/${organisationId}/whatsapp-credits/options`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  userManuals = {
    /**
     * No description
     *
     * @tags User manuals
     * @name GetCourses
     * @request GET:/user-manuals/courses
     * @secure
     */
    getCourses: (params: RequestParams = {}) =>
      this.request<Course[], any>({
        path: `/user-manuals/courses`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User manuals
     * @name CreateCourse
     * @request POST:/user-manuals/courses
     * @secure
     */
    createCourse: (data: CourseCreateRequest, params: RequestParams = {}) =>
      this.request<Course, any>({
        path: `/user-manuals/courses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User manuals
     * @name ReorderCourses
     * @request PUT:/user-manuals/courses/order
     * @secure
     */
    reorderCourses: (data: ReorderRequest, params: RequestParams = {}) =>
      this.request<Course[], any>({
        path: `/user-manuals/courses/order`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User manuals
     * @name UpdateCourse
     * @request PUT:/user-manuals/courses/{courseId}
     * @secure
     */
    updateCourse: (courseId: string, data: CourseUpdateRequest, params: RequestParams = {}) =>
      this.request<Course, any>({
        path: `/user-manuals/courses/${courseId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User manuals
     * @name DeleteCourse
     * @request DELETE:/user-manuals/courses/{courseId}
     * @secure
     */
    deleteCourse: (courseId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user-manuals/courses/${courseId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User manuals
     * @name GetModules
     * @request GET:/user-manuals/courses/{courseId}/modules
     * @secure
     */
    getModules: (courseId: string, params: RequestParams = {}) =>
      this.request<Module[], any>({
        path: `/user-manuals/courses/${courseId}/modules`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User manuals
     * @name CreateModule
     * @request POST:/user-manuals/courses/{courseId}/modules
     * @secure
     */
    createModule: (courseId: string, data: ModuleCreateRequest, params: RequestParams = {}) =>
      this.request<Module, any>({
        path: `/user-manuals/courses/${courseId}/modules`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User manuals
     * @name ReorderModules
     * @request PUT:/user-manuals/courses/{courseId}/modules/order
     * @secure
     */
    reorderModules: (courseId: string, data: ReorderRequest, params: RequestParams = {}) =>
      this.request<Module[], any>({
        path: `/user-manuals/courses/${courseId}/modules/order`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User manuals
     * @name UpdateModule
     * @request PUT:/user-manuals/courses/{courseId}/modules/{moduleId}
     * @secure
     */
    updateModule: (courseId: string, moduleId: string, data: ModuleUpdateRequest, params: RequestParams = {}) =>
      this.request<Module, any>({
        path: `/user-manuals/courses/${courseId}/modules/${moduleId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User manuals
     * @name DeleteModule
     * @request DELETE:/user-manuals/courses/{courseId}/modules/{moduleId}
     * @secure
     */
    deleteModule: (courseId: string, moduleId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user-manuals/courses/${courseId}/modules/${moduleId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  upload = {
    /**
     * No description
     *
     * @tags Upload
     * @name UploadImage
     * @request POST:/organisations/{organisationId}/upload/images
     * @secure
     */
    uploadImage: (
      organisationId: string,
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<UploadedItem, any>({
        path: `/organisations/${organisationId}/upload/images`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Upload
     * @name UploadGlobalImage
     * @request POST:/upload/images
     * @secure
     */
    uploadGlobalImage: (
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<UploadedItem, any>({
        path: `/upload/images`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Upload
     * @name UploadAudio
     * @request POST:/organisations/{organisationId}/upload/audio
     * @secure
     */
    uploadAudio: (
      organisationId: string,
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<UploadedItem, any>({
        path: `/organisations/${organisationId}/upload/audio`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Upload
     * @name UploadGlobalAudio
     * @request POST:/upload/audio
     * @secure
     */
    uploadGlobalAudio: (
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<UploadedItem, any>({
        path: `/upload/audio`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Upload
     * @name GetGlobalImagesCategories
     * @request GET:/images/categories
     * @secure
     */
    getGlobalImagesCategories: (params: RequestParams = {}) =>
      this.request<ImagesCategoriesTree, any>({
        path: `/images/categories`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  trainings = {
    /**
     * No description
     *
     * @tags Trainings
     * @name GetCourses
     * @request GET:/organisations/{organisationId}/trainings/courses
     * @secure
     */
    getCourses: (organisationId: string, params: RequestParams = {}) =>
      this.request<Course[], any>({
        path: `/organisations/${organisationId}/trainings/courses`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trainings
     * @name CreateCourse
     * @request POST:/organisations/{organisationId}/trainings/courses
     * @secure
     */
    createCourse: (organisationId: string, data: CourseCreateRequest, params: RequestParams = {}) =>
      this.request<Course, any>({
        path: `/organisations/${organisationId}/trainings/courses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trainings
     * @name ReorderCourses
     * @request PUT:/organisations/{organisationId}/trainings/courses/order
     * @secure
     */
    reorderCourses: (organisationId: string, data: ReorderRequest, params: RequestParams = {}) =>
      this.request<Course[], any>({
        path: `/organisations/${organisationId}/trainings/courses/order`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trainings
     * @name UpdateCourse
     * @request PUT:/organisations/{organisationId}/trainings/courses/{courseId}
     * @secure
     */
    updateCourse: (organisationId: string, courseId: string, data: CourseUpdateRequest, params: RequestParams = {}) =>
      this.request<Course, any>({
        path: `/organisations/${organisationId}/trainings/courses/${courseId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trainings
     * @name DeleteCourse
     * @request DELETE:/organisations/{organisationId}/trainings/courses/{courseId}
     * @secure
     */
    deleteCourse: (organisationId: string, courseId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/trainings/courses/${courseId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trainings
     * @name GetModules
     * @request GET:/organisations/{organisationId}/trainings/courses/{courseId}/modules
     * @secure
     */
    getModules: (organisationId: string, courseId: string, params: RequestParams = {}) =>
      this.request<Module[], any>({
        path: `/organisations/${organisationId}/trainings/courses/${courseId}/modules`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trainings
     * @name CreateModule
     * @request POST:/organisations/{organisationId}/trainings/courses/{courseId}/modules
     * @secure
     */
    createModule: (organisationId: string, courseId: string, data: ModuleCreateRequest, params: RequestParams = {}) =>
      this.request<Module, any>({
        path: `/organisations/${organisationId}/trainings/courses/${courseId}/modules`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trainings
     * @name ReorderModules
     * @request PUT:/organisations/{organisationId}/trainings/courses/{courseId}/modules/order
     * @secure
     */
    reorderModules: (organisationId: string, courseId: string, data: ReorderRequest, params: RequestParams = {}) =>
      this.request<Module[], any>({
        path: `/organisations/${organisationId}/trainings/courses/${courseId}/modules/order`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trainings
     * @name UpdateModule
     * @request PUT:/organisations/{organisationId}/trainings/courses/{courseId}/modules/{moduleId}
     * @secure
     */
    updateModule: (
      organisationId: string,
      courseId: string,
      moduleId: string,
      data: ModuleUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<Module, any>({
        path: `/organisations/${organisationId}/trainings/courses/${courseId}/modules/${moduleId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trainings
     * @name DeleteModule
     * @request DELETE:/organisations/{organisationId}/trainings/courses/{courseId}/modules/{moduleId}
     * @secure
     */
    deleteModule: (organisationId: string, courseId: string, moduleId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/trainings/courses/${courseId}/modules/${moduleId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  subscriptions = {
    /**
     * No description
     *
     * @tags Subscriptions
     * @name GetSubscription
     * @request GET:/organisations/{organisationId}/subscriptions
     * @secure
     */
    getSubscription: (organisationId: string, params: RequestParams = {}) =>
      this.request<Subscription, any>({
        path: `/organisations/${organisationId}/subscriptions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name GetSubscriptionNextPayment
     * @request GET:/organisations/{organisationId}/subscriptions/next-payment
     * @secure
     */
    getSubscriptionNextPayment: (organisationId: string, params: RequestParams = {}) =>
      this.request<SubscriptionNextPayment, any>({
        path: `/organisations/${organisationId}/subscriptions/next-payment`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name GetRemainingAmount
     * @request GET:/organisations/{organisationId}/subscriptions/remaining-amount
     * @secure
     */
    getRemainingAmount: (organisationId: string, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/organisations/${organisationId}/subscriptions/remaining-amount`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name GetVatRate
     * @request GET:/organisations/{organisationId}/subscriptions/vat-rate
     * @secure
     */
    getVatRate: (organisationId: string, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/organisations/${organisationId}/subscriptions/vat-rate`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name CreateSubscriptionOptionPaymentAndSubscription
     * @request POST:/organisations/{organisationId}/subscriptions/option-payment
     * @secure
     */
    createSubscriptionOptionPaymentAndSubscription: (
      organisationId: string,
      data: SubscriptionOptionPaymentAndSubscriptionCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<SubscriptionOptionPaymentAndSubscriptionCreateResponse | null, any>({
        path: `/organisations/${organisationId}/subscriptions/option-payment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name CancelSubscriptionOptionSubscription
     * @request POST:/organisations/{organisationId}/subscriptions/cancel-subscription
     * @secure
     */
    cancelSubscriptionOptionSubscription: (organisationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/subscriptions/cancel-subscription`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name InitializeSubscriptionOptions
     * @request GET:/organisations/{organisationId}/subscriptions/initialize-subscription-options
     * @secure
     */
    initializeSubscriptionOptions: (organisationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/subscriptions/initialize-subscription-options`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name InitializeWhatsappOptions
     * @request GET:/organisations/{organisationId}/subscriptions/initialize-whatsapp-options
     * @secure
     */
    initializeWhatsappOptions: (organisationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/subscriptions/initialize-whatsapp-options`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name DownloadInvoice
     * @request GET:/organisations/{organisationId}/subscriptions/download-invoice/{paymentId}
     * @secure
     */
    downloadInvoice: (organisationId: string, paymentId: string, params: RequestParams = {}) =>
      this.request<ExpressMulterFile, any>({
        path: `/organisations/${organisationId}/subscriptions/download-invoice/${paymentId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscriptions
     * @name GetSubscriptionOptionDiscountCode
     * @request GET:/organisations/{organisationId}/subscriptions/option-payment/discount-code
     * @secure
     */
    getSubscriptionOptionDiscountCode: (
      organisationId: string,
      query: {
        code: string;
        subscriptionOptionId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DiscountCode, any>({
        path: `/organisations/${organisationId}/subscriptions/option-payment/discount-code`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  paymentsWebhooks = {
    /**
     * No description
     *
     * @tags PaymentsWebhooks
     * @name SubscriptionOptionsPaymentWebhook
     * @request POST:/organisations/{organisationId}/subscription-options/payments/webhook
     */
    subscriptionOptionsPaymentWebhook: (
      organisationId: string,
      data: {
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/subscription-options/payments/webhook`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentsWebhooks
     * @name SubscriptionOptionsRecurringPaymentWebhook
     * @request POST:/organisations/{organisationId}/subscription/recurring-payments/webhook
     */
    subscriptionOptionsRecurringPaymentWebhook: (
      organisationId: string,
      data: {
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/subscription/recurring-payments/webhook`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentsWebhooks
     * @name PaidCoursesPaymentWebhook
     * @request POST:/organisations/{organisationId}/paid-courses/payments/webhook
     */
    paidCoursesPaymentWebhook: (
      organisationId: string,
      data: {
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/paid-courses/payments/webhook`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentsWebhooks
     * @name WhatsappCreditsPaymentWebhook
     * @request POST:/organisations/{organisationId}/whatsapp-credits/payments/webhook
     */
    whatsappCreditsPaymentWebhook: (
      organisationId: string,
      data: {
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/whatsapp-credits/payments/webhook`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentsWebhooks
     * @name PlugAndPayNewOrder
     * @request POST:/organisations/plug-and-pay/new-organisation/webhook
     */
    plugAndPayNewOrder: (data: IRecordOfAny, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organisations/plug-and-pay/new-organisation/webhook`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  globalSubscriptions = {
    /**
     * No description
     *
     * @tags GlobalSubscriptions
     * @name GetSubscriptionsOptions
     * @request GET:/global/subscriptions/options
     */
    getSubscriptionsOptions: (params: RequestParams = {}) =>
      this.request<SubscriptionOption[], any>({
        path: `/global/subscriptions/options`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  shortcuts = {
    /**
     * No description
     *
     * @tags Shortcuts
     * @name GetShortcutData
     * @request GET:/shortcuts
     */
    getShortcutData: (
      query: {
        code: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data: string;
        } | null,
        any
      >({
        path: `/shortcuts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  salons = {
    /**
     * No description
     *
     * @tags Salons
     * @name GetSalons
     * @request GET:/organisations/{organisationId}/salons
     * @secure
     */
    getSalons: (organisationId: string, params: RequestParams = {}) =>
      this.request<Salon[], any>({
        path: `/organisations/${organisationId}/salons`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Salons
     * @name CreateSalon
     * @request POST:/organisations/{organisationId}/salons
     * @secure
     */
    createSalon: (organisationId: string, data: SalonCreateRequest, params: RequestParams = {}) =>
      this.request<Salon, any>({
        path: `/organisations/${organisationId}/salons`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Salons
     * @name GetSalon
     * @request GET:/organisations/{organisationId}/salons/{salonId}
     * @secure
     */
    getSalon: (organisationId: string, salonId: string, params: RequestParams = {}) =>
      this.request<Salon | null, any>({
        path: `/organisations/${organisationId}/salons/${salonId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Salons
     * @name UpdateSalon
     * @request PUT:/organisations/{organisationId}/salons/{id}
     * @secure
     */
    updateSalon: (organisationId: string, id: string, data: SalonUpdateRequest, params: RequestParams = {}) =>
      this.request<Salon, any>({
        path: `/organisations/${organisationId}/salons/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  questionnaires = {
    /**
     * No description
     *
     * @tags Questionnaires
     * @name GetQuestionnaire
     * @request GET:/organisations/{organisationId}/salons/{salonId}/questionnaires/{questionnaireId}
     * @secure
     */
    getQuestionnaire: (organisationId: string, salonId: string, questionnaireId: string, params: RequestParams = {}) =>
      this.request<Questionnaire, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/questionnaires/${questionnaireId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Questionnaires
     * @name UpdateQuestionnaire
     * @request PUT:/organisations/{organisationId}/salons/{salonId}/questionnaires/{questionnaireId}
     * @secure
     */
    updateQuestionnaire: (
      organisationId: string,
      salonId: string,
      questionnaireId: string,
      data: QuestionnaireUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<Questionnaire, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/questionnaires/${questionnaireId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Questionnaires
     * @name GetQuestionnaires
     * @request GET:/organisations/{organisationId}/salons/{salonId}/questionnaires
     * @secure
     */
    getQuestionnaires: (organisationId: string, salonId: string, params: RequestParams = {}) =>
      this.request<Questionnaire[] | null, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/questionnaires`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Questionnaires
     * @name CreateQuestionnaire
     * @request POST:/organisations/{organisationId}/salons/{salonId}/questionnaires
     * @secure
     */
    createQuestionnaire: (
      organisationId: string,
      salonId: string,
      data: QuestionnaireCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<Questionnaire, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/questionnaires`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Questionnaires
     * @name CreateQuestionnaireSubmissionRequest
     * @request POST:/organisations/{organisationId}/salons/{salonId}/questionnaireSubmissions
     * @secure
     */
    createQuestionnaireSubmissionRequest: (
      organisationId: string,
      salonId: string,
      data: QuestionnaireSubmissionRequest,
      params: RequestParams = {},
    ) =>
      this.request<QuestionnaireSubmission, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/questionnaireSubmissions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Questionnaires
     * @name GetCustomerQuestionnaireSubmissions
     * @request GET:/organisations/{organisationId}/salons/{salonId}/questionnaireSubmissions
     * @secure
     */
    getCustomerQuestionnaireSubmissions: (
      organisationId: string,
      salonId: string,
      query: {
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<QuestionnaireSubmission[] | null, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/questionnaireSubmissions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Questionnaires
     * @name GetRecentCustomerQuestionnaireSubmissions
     * @request GET:/organisations/{organisationId}/salons/{salonId}/questionnaireSubmissions/recent
     * @secure
     */
    getRecentCustomerQuestionnaireSubmissions: (
      organisationId: string,
      salonId: string,
      query: {
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<QuestionnaireSubmission[], any>({
        path: `/organisations/${organisationId}/salons/${salonId}/questionnaireSubmissions/recent`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Questionnaires
     * @name GetQuestionnaireSubmission
     * @request GET:/organisations/{organisationId}/salons/{salonId}/questionnaireSubmissions/{questionnaireSubmissionId}
     * @secure
     */
    getQuestionnaireSubmission: (
      organisationId: string,
      salonId: string,
      questionnaireSubmissionId: string,
      params: RequestParams = {},
    ) =>
      this.request<QuestionnaireSubmission, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/questionnaireSubmissions/${questionnaireSubmissionId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Questionnaires
     * @name AddQuestionSubmission
     * @request POST:/organisations/{organisationId}/salons/{salonId}/questionnaireSubmissions/{questionnaireSubmissionId}/questionSubmission
     * @secure
     */
    addQuestionSubmission: (
      organisationId: string,
      salonId: string,
      questionnaireSubmissionId: string,
      data: {
        isLastQuestion: boolean;
        answer?: string[];
        questionId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<QuestionSubmission | null, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/questionnaireSubmissions/${questionnaireSubmissionId}/questionSubmission`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  permissions = {
    /**
     * No description
     *
     * @tags Permissions
     * @name GetRoles
     * @request GET:/organisations/{organisationId}/permissions/roles
     * @secure
     */
    getRoles: (organisationId: string, params: RequestParams = {}) =>
      this.request<SalonRole[], any>({
        path: `/organisations/${organisationId}/permissions/roles`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permissions
     * @name CreateRole
     * @request POST:/organisations/{organisationId}/permissions/roles
     * @secure
     */
    createRole: (organisationId: string, data: SalonRoleCreateRequest, params: RequestParams = {}) =>
      this.request<SalonRole, any>({
        path: `/organisations/${organisationId}/permissions/roles`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Permissions
     * @name UpdateRole
     * @request PUT:/organisations/{organisationId}/permissions/roles/{roleId}
     * @secure
     */
    updateRole: (organisationId: string, roleId: string, data: SalonRoleUpdateRequest, params: RequestParams = {}) =>
      this.request<SalonRole, any>({
        path: `/organisations/${organisationId}/permissions/roles/${roleId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  paidCourses = {
    /**
     * No description
     *
     * @tags PaidCourses
     * @name GetCourses
     * @request GET:/organisations/{organisationId}/paid-courses
     * @secure
     */
    getCourses: (organisationId: string, params: RequestParams = {}) =>
      this.request<PaidCourse[], any>({
        path: `/organisations/${organisationId}/paid-courses`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaidCourses
     * @name CreateCoursePayment
     * @request POST:/organisations/{organisationId}/paid-courses/{courseId}/payment
     * @secure
     */
    createCoursePayment: (
      organisationId: string,
      courseId: string,
      query: {
        code: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaidCoursePaymentCreateResponse, any>({
        path: `/organisations/${organisationId}/paid-courses/${courseId}/payment`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaidCourses
     * @name GetModules
     * @request GET:/organisations/{organisationId}/paid-courses/{courseId}/modules
     * @secure
     */
    getModules: (courseId: string, organisationId: string, params: RequestParams = {}) =>
      this.request<Module[], any>({
        path: `/organisations/${organisationId}/paid-courses/${courseId}/modules`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaidCourses
     * @name GetPaidCourseDiscountCode
     * @request GET:/organisations/{organisationId}/paid-courses/{courseId}/discount-codes/{code}
     * @secure
     */
    getPaidCourseDiscountCode: (organisationId: string, code: string, courseId: string, params: RequestParams = {}) =>
      this.request<DiscountCode, any>({
        path: `/organisations/${organisationId}/paid-courses/${courseId}/discount-codes/${code}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  globalPaidCourses = {
    /**
     * No description
     *
     * @tags GlobalPaidCourses
     * @name GetCourses
     * @request GET:/global/paid-courses
     * @secure
     */
    getCourses: (params: RequestParams = {}) =>
      this.request<GlobalPaidCourse[], any>({
        path: `/global/paid-courses`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalPaidCourses
     * @name CreateCourse
     * @request POST:/global/paid-courses
     * @secure
     */
    createCourse: (data: PaidCourseCreateRequest, params: RequestParams = {}) =>
      this.request<GlobalPaidCourse, any>({
        path: `/global/paid-courses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalPaidCourses
     * @name ReorderCourses
     * @request PUT:/global/paid-courses/order
     * @secure
     */
    reorderCourses: (data: ReorderRequest, params: RequestParams = {}) =>
      this.request<GlobalPaidCourse[], any>({
        path: `/global/paid-courses/order`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalPaidCourses
     * @name UpdateCourse
     * @request PUT:/global/paid-courses/{courseId}
     * @secure
     */
    updateCourse: (courseId: string, data: PaidCourseUpdateRequest, params: RequestParams = {}) =>
      this.request<GlobalPaidCourse, any>({
        path: `/global/paid-courses/${courseId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalPaidCourses
     * @name DeleteCourse
     * @request DELETE:/global/paid-courses/{courseId}
     * @secure
     */
    deleteCourse: (courseId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/global/paid-courses/${courseId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalPaidCourses
     * @name GetModules
     * @request GET:/global/paid-courses/{courseId}/modules
     * @secure
     */
    getModules: (courseId: string, params: RequestParams = {}) =>
      this.request<Module[], any>({
        path: `/global/paid-courses/${courseId}/modules`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalPaidCourses
     * @name CreateModule
     * @request POST:/global/paid-courses/{courseId}/modules
     * @secure
     */
    createModule: (courseId: string, data: ModuleCreateRequest, params: RequestParams = {}) =>
      this.request<Module, any>({
        path: `/global/paid-courses/${courseId}/modules`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalPaidCourses
     * @name ReorderModules
     * @request PUT:/global/paid-courses/{courseId}/modules/order
     * @secure
     */
    reorderModules: (courseId: string, data: ReorderRequest, params: RequestParams = {}) =>
      this.request<Module[], any>({
        path: `/global/paid-courses/${courseId}/modules/order`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalPaidCourses
     * @name UpdateModule
     * @request PUT:/global/paid-courses/{courseId}/modules/{moduleId}
     * @secure
     */
    updateModule: (courseId: string, moduleId: string, data: ModuleUpdateRequest, params: RequestParams = {}) =>
      this.request<Module, any>({
        path: `/global/paid-courses/${courseId}/modules/${moduleId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalPaidCourses
     * @name DeleteModule
     * @request DELETE:/global/paid-courses/{courseId}/modules/{moduleId}
     * @secure
     */
    deleteModule: (courseId: string, moduleId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/global/paid-courses/${courseId}/modules/${moduleId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  organisations = {
    /**
     * No description
     *
     * @tags Organisations
     * @name GetOrganisationCreateChallenge
     * @request GET:/organisations/challenge
     */
    getOrganisationCreateChallenge: (
      query: {
        slug: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Challenge, any>({
        path: `/organisations/challenge`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name GetPublicOrganisationBySlugOrName
     * @request GET:/organisations/public
     */
    getPublicOrganisationBySlugOrName: (
      query?: {
        slug?: string;
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PublicOrganisation | null, any>({
        path: `/organisations/public`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name GetOrganisationCustomerSettings
     * @request GET:/organisations/{organisationId}/customer-settings
     * @secure
     */
    getOrganisationCustomerSettings: (organisationId: string, params: RequestParams = {}) =>
      this.request<OrganisationCustomerSettings, any>({
        path: `/organisations/${organisationId}/customer-settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name GetOrganisation
     * @request GET:/organisations/{organisationId}
     * @secure
     */
    getOrganisation: (organisationId: string, params: RequestParams = {}) =>
      this.request<Organisation | null, any>({
        path: `/organisations/${organisationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name UpdateOrganisation
     * @request PUT:/organisations/{organisationId}
     * @secure
     */
    updateOrganisation: (organisationId: string, data: OrganisationUpdateRequest, params: RequestParams = {}) =>
      this.request<Organisation, any>({
        path: `/organisations/${organisationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organisations
     * @name CreateOrganisation
     * @request POST:/organisations
     */
    createOrganisation: (data: OrganisationCreateRequest, params: RequestParams = {}) =>
      this.request<
        {
          employee: EmployeeUser;
          organisation: Organisation;
        },
        any
      >({
        path: `/organisations`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  globalOrganisations = {
    /**
     * No description
     *
     * @tags GlobalOrganisations
     * @name GetOrganisations
     * @request GET:/global/organisations
     * @secure
     */
    getOrganisations: (params: RequestParams = {}) =>
      this.request<Organisation[], any>({
        path: `/global/organisations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalOrganisations
     * @name UpdateOrganisation
     * @request PUT:/global/organisations
     * @secure
     */
    updateOrganisation: (data: GlobalAdminUpdateOrganisationRequest, params: RequestParams = {}) =>
      this.request<Organisation, any>({
        path: `/global/organisations`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalOrganisations
     * @name DropOrganisation
     * @request DELETE:/global/organisations/{organisationId}
     * @secure
     */
    dropOrganisation: (organisationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/global/organisations/${organisationId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalOrganisations
     * @name GetDiscountCodes
     * @request GET:/global/organisations/discount-codes
     * @secure
     */
    getDiscountCodes: (params: RequestParams = {}) =>
      this.request<GlobalDiscountCode[], any>({
        path: `/global/organisations/discount-codes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  messageTemplates = {
    /**
     * No description
     *
     * @tags MessageTemplates
     * @name GetNewQuestionnaireMessageTemplates
     * @request GET:/message-templates/new-questionnaire
     * @secure
     */
    getNewQuestionnaireMessageTemplates: (params: RequestParams = {}) =>
      this.request<MessageTemplate[], any>({
        path: `/message-templates/new-questionnaire`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  healthCheck = {
    /**
     * No description
     *
     * @tags HealthCheck
     * @name HealthCheck
     * @request GET:/health-check
     */
    healthCheck: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/health-check`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HealthCheck
     * @name HealthCheckWithoutDbConnection
     * @request GET:/health-check/no-db
     */
    healthCheckWithoutDbConnection: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/health-check/no-db`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  customerSkinAnalysis = {
    /**
     * No description
     *
     * @tags CustomerSkinAnalysis
     * @name GetCustomerSkinAnalysis
     * @request GET:/organisations/{organisationId}/salons/{salonId}/customers/{customerId}/skin-analysis
     * @secure
     */
    getCustomerSkinAnalysis: (organisationId: string, salonId: string, customerId: string, params: RequestParams = {}) =>
      this.request<SkinAnalysis | null, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/customers/${customerId}/skin-analysis`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerSkinAnalysis
     * @name UpdateCustomerSkinAnalysis
     * @request PUT:/organisations/{organisationId}/salons/{salonId}/customers/{customerId}/skin-analysis
     * @secure
     */
    updateCustomerSkinAnalysis: (
      organisationId: string,
      salonId: string,
      customerId: string,
      data: SkinAnalysisUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<SkinAnalysis, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/customers/${customerId}/skin-analysis`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  employees = {
    /**
     * No description
     *
     * @tags Employees
     * @name GetEmployees
     * @request GET:/organisations/{organisationId}/employees
     * @secure
     */
    getEmployees: (
      organisationId: string,
      query?: {
        salonId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmployeeUser[], any>({
        path: `/organisations/${organisationId}/employees`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employees
     * @name CreateEmployee
     * @request POST:/organisations/{organisationId}/employees
     * @secure
     */
    createEmployee: (organisationId: string, data: EmployeeCreateRequest, params: RequestParams = {}) =>
      this.request<EmployeeUser, any>({
        path: `/organisations/${organisationId}/employees`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employees
     * @name GetCurrentEmployeeData
     * @request GET:/organisations/{organisationId}/employees/me
     * @secure
     */
    getCurrentEmployeeData: (organisationId: string, params: RequestParams = {}) =>
      this.request<EmployeeUser, any>({
        path: `/organisations/${organisationId}/employees/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employees
     * @name GetEmployeesActivities
     * @request POST:/organisations/{organisationId}/employees/activities
     * @secure
     */
    getEmployeesActivities: (organisationId: string, data: EmployeeActivitySearchRequest, params: RequestParams = {}) =>
      this.request<EmployeeActivity[], any>({
        path: `/organisations/${organisationId}/employees/activities`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employees
     * @name GetEmployeeEffectivePermissions
     * @request GET:/organisations/{organisationId}/employees/{employeeId}/effectivePermissions
     * @secure
     */
    getEmployeeEffectivePermissions: (organisationId: string, employeeId: string, params: RequestParams = {}) =>
      this.request<EffectivePermissions, any>({
        path: `/organisations/${organisationId}/employees/${employeeId}/effectivePermissions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employees
     * @name UpdateCurrentEmployeeLanguage
     * @request PUT:/organisations/{organisationId}/employees/me/language
     * @secure
     */
    updateCurrentEmployeeLanguage: (
      organisationId: string,
      data: {
        language: SupportedLanguage;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmployeeUser, any>({
        path: `/organisations/${organisationId}/employees/me/language`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employees
     * @name UpdateEmployee
     * @request PUT:/organisations/{organisationId}/employees/{id}
     * @secure
     */
    updateEmployee: (organisationId: string, id: string, data: EmployeeEditRequest, params: RequestParams = {}) =>
      this.request<EmployeeUser, any>({
        path: `/organisations/${organisationId}/employees/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  documents = {
    /**
     * No description
     *
     * @tags Documents
     * @name GetDocuments
     * @request GET:/organisations/{organisationId}/documents
     * @secure
     */
    getDocuments: (organisationId: string, params: RequestParams = {}) =>
      this.request<Document[], any>({
        path: `/organisations/${organisationId}/documents`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name CreateDocument
     * @request POST:/organisations/{organisationId}/documents
     * @secure
     */
    createDocument: (organisationId: string, data: DocumentCreateRequest, params: RequestParams = {}) =>
      this.request<Document, any>({
        path: `/organisations/${organisationId}/documents`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name UpdateDocument
     * @request PUT:/organisations/{organisationId}/documents/{documentId}
     * @secure
     */
    updateDocument: (organisationId: string, documentId: string, data: DocumentUpdateRequest, params: RequestParams = {}) =>
      this.request<Document, any>({
        path: `/organisations/${organisationId}/documents/${documentId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  dashboardConfiguration = {
    /**
     * No description
     *
     * @tags DashboardConfiguration
     * @name GetDashboardsConfigurations
     * @request GET:/organisations/{organisationId}/salons/{salonId}/dashboard-configurations
     * @secure
     */
    getDashboardsConfigurations: (organisationId: string, salonId: string, params: RequestParams = {}) =>
      this.request<DashboardConfiguration[], any>({
        path: `/organisations/${organisationId}/salons/${salonId}/dashboard-configurations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DashboardConfiguration
     * @name UpdateDashboardsConfigurations
     * @request POST:/organisations/{organisationId}/salons/{salonId}/dashboard-configurations
     * @secure
     */
    updateDashboardsConfigurations: (
      organisationId: string,
      salonId: string,
      data: DashboardConfigurationsUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<DashboardConfiguration[], any>({
        path: `/organisations/${organisationId}/salons/${salonId}/dashboard-configurations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  customers = {
    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomerByEmail
     * @request GET:/organisations/{organisationId}/customers/email
     * @secure
     */
    getCustomerByEmail: (
      organisationId: string,
      query: {
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerUser | null, any>({
        path: `/organisations/${organisationId}/customers/email`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreateCustomer
     * @request POST:/organisations/{organisationId}/customers
     * @secure
     */
    createCustomer: (organisationId: string, data: CustomerCreateRequest, params: RequestParams = {}) =>
      this.request<CustomerUser, any>({
        path: `/organisations/${organisationId}/customers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomers
     * @request GET:/organisations/{organisationId}/customers
     * @secure
     */
    getCustomers: (
      organisationId: string,
      query: {
        salonId: string;
        includeDeleted?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerUser[], any>({
        path: `/organisations/${organisationId}/customers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name UpdateCurrentCustomer
     * @request PUT:/organisations/{organisationId}/customers/me
     * @secure
     */
    updateCurrentCustomer: (organisationId: string, data: CurrentCustomerUpdateRequest, params: RequestParams = {}) =>
      this.request<CustomerUser, any>({
        path: `/organisations/${organisationId}/customers/me`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCurrentCustomer
     * @request GET:/organisations/{organisationId}/customers/me
     * @secure
     */
    getCurrentCustomer: (organisationId: string, params: RequestParams = {}) =>
      this.request<CustomerUser, any>({
        path: `/organisations/${organisationId}/customers/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCurrentCustomerDocuments
     * @request GET:/organisations/{organisationId}/customers/me/documents
     * @secure
     */
    getCurrentCustomerDocuments: (
      organisationId: string,
      query: {
        salonId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CurrentCustomerDocument[], any>({
        path: `/organisations/${organisationId}/customers/me/documents`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomerActivities
     * @request GET:/organisations/{organisationId}/customers/activities
     * @secure
     */
    getCustomerActivities: (
      organisationId: string,
      query: {
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerActivity[], any>({
        path: `/organisations/${organisationId}/customers/activities`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name UpdateCustomer
     * @request PUT:/organisations/{organisationId}/customers/{customerId}
     * @secure
     */
    updateCustomer: (organisationId: string, customerId: string, data: CustomerEditRequest, params: RequestParams = {}) =>
      this.request<CustomerUser, any>({
        path: `/organisations/${organisationId}/customers/${customerId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomerDocuments
     * @request GET:/organisations/{organisationId}/customers/{customerId}/documents
     * @secure
     */
    getCustomerDocuments: (
      organisationId: string,
      customerId: string,
      query: {
        salonId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDocument[], any>({
        path: `/organisations/${organisationId}/customers/${customerId}/documents`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name SendCustomerDocument
     * @request POST:/organisations/{organisationId}/customers/{customerId}/documents
     * @secure
     */
    sendCustomerDocument: (
      organisationId: string,
      customerId: string,
      query: {
        salonId: string;
      },
      data: SendCustomerDocumentRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/customers/${customerId}/documents`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name DeleteCustomerDocument
     * @request DELETE:/organisations/{organisationId}/customers/{customerId}/documents/{customerDocumentId}
     * @secure
     */
    deleteCustomerDocument: (
      organisationId: string,
      customerId: string,
      customerDocumentId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/customers/${customerId}/documents/${customerDocumentId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name UpdateCustomerDocument
     * @request PUT:/organisations/{organisationId}/customers/{customerId}/documents/{documentId}
     * @secure
     */
    updateCustomerDocument: (
      organisationId: string,
      customerId: string,
      documentId: string,
      data: {
        status: CustomerConsentStatus;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerDocument, any>({
        path: `/organisations/${organisationId}/customers/${customerId}/documents/${documentId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name SearchCustomers
     * @request POST:/organisations/{organisationId}/customers/search
     * @secure
     */
    searchCustomers: (organisationId: string, data: CustomersSearchRequest, params: RequestParams = {}) =>
      this.request<CustomerUser[], any>({
        path: `/organisations/${organisationId}/customers/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetAllCustomers
     * @request GET:/organisations/{organisationId}/customers/all
     * @secure
     */
    getAllCustomers: (
      organisationId: string,
      query?: {
        includeDeleted?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerUser[], any>({
        path: `/organisations/${organisationId}/customers/all`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomer
     * @request GET:/organisations/{organisationId}/customers/{id}
     * @secure
     */
    getCustomer: (
      organisationId: string,
      id: string,
      query: {
        salonId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerUser | null, any>({
        path: `/organisations/${organisationId}/customers/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name HardDeleteCustomer
     * @request DELETE:/organisations/{organisationId}/customers/{id}
     * @secure
     */
    hardDeleteCustomer: (organisationId: string, id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/customers/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreateCustomersInBulk
     * @request POST:/organisations/{organisationId}/customers/bulk
     * @secure
     */
    createCustomersInBulk: (organisationId: string, data: CustomersBulkCreateRequest, params: RequestParams = {}) =>
      this.request<
        {
          isNew: boolean;
          customer: CustomerUser;
        }[],
        any
      >({
        path: `/organisations/${organisationId}/customers/bulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  customerComments = {
    /**
     * No description
     *
     * @tags CustomerComments
     * @name GetCustomerComments
     * @request GET:/organisations/{organisationId}/customer-comments
     * @secure
     */
    getCustomerComments: (
      organisationId: string,
      query: {
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerComment[], any>({
        path: `/organisations/${organisationId}/customer-comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerComments
     * @name AddCustomerComment
     * @request POST:/organisations/{organisationId}/customer-comments
     * @secure
     */
    addCustomerComment: (
      organisationId: string,
      query: {
        customerId: string;
      },
      data: CustomerCommentCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerComment, any>({
        path: `/organisations/${organisationId}/customer-comments`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerComments
     * @name UpdateCustomerComment
     * @request PUT:/organisations/{organisationId}/customer-comments/{commentId}
     * @secure
     */
    updateCustomerComment: (
      organisationId: string,
      commentId: string,
      query: {
        customerId: string;
      },
      data: CustomerCommentUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerComment, any>({
        path: `/organisations/${organisationId}/customer-comments/${commentId}`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerComments
     * @name DeleteCustomerComment
     * @request DELETE:/organisations/{organisationId}/customer-comments/{commentId}
     * @secure
     */
    deleteCustomerComment: (
      organisationId: string,
      commentId: string,
      query: {
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/customer-comments/${commentId}`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),
  };
  globalAuthentication = {
    /**
     * No description
     *
     * @tags GlobalAuthentication
     * @name GlobalAdminLogin
     * @request POST:/global/authentication/login
     */
    globalAdminLogin: (
      data: {
        password: string;
        username: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GlobalAdminJwtClaims, any>({
        path: `/global/authentication/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalAuthentication
     * @name ImpersonateEmployee
     * @request POST:/global/authentication/impersonate
     * @secure
     */
    impersonateEmployee: (data: ImpersonateEmployeeRequest, params: RequestParams = {}) =>
      this.request<EmployeeJwtClaims, any>({
        path: `/global/authentication/impersonate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  authentication = {
    /**
     * No description
     *
     * @tags Authentication
     * @name RequestCustomerLoginCode
     * @request POST:/organisations/{organisationId}/authentication/customer-login-request
     */
    requestCustomerLoginCode: (
      organisationId: string,
      data: {
        slug: string;
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/authentication/customer-login-request`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name VerifyCustomerLoginCode
     * @request POST:/organisations/{organisationId}/authentication/customer-login-request/verify
     */
    verifyCustomerLoginCode: (
      organisationId: string,
      data: {
        slug: string;
        confirmationCode: string;
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerJwtClaims, any>({
        path: `/organisations/${organisationId}/authentication/customer-login-request/verify`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name EmployeeLogin
     * @request POST:/organisations/{organisationId}/authentication/login
     */
    employeeLogin: (
      organisationId: string,
      data: {
        password: string;
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmployeeJwtClaims, any>({
        path: `/organisations/${organisationId}/authentication/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name RequestEmployeePasswordReset
     * @request POST:/organisations/{organisationId}/authentication/request-password-reset
     */
    requestEmployeePasswordReset: (
      organisationId: string,
      data: {
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/authentication/request-password-reset`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name ResetEmployeePassword
     * @request POST:/organisations/{organisationId}/authentication/password-reset
     */
    resetEmployeePassword: (organisationId: string, data: EmployeePasswordResetRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/authentication/password-reset`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  advices = {
    /**
     * No description
     *
     * @tags Advices
     * @name GetAdvices
     * @request GET:/organisations/{organisationId}/salons/{salonId}/advices
     * @secure
     */
    getAdvices: (organisationId: string, salonId: string, params: RequestParams = {}) =>
      this.request<Advice[], any>({
        path: `/organisations/${organisationId}/salons/${salonId}/advices`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advices
     * @name CreateAdvice
     * @request POST:/organisations/{organisationId}/salons/{salonId}/advices
     * @secure
     */
    createAdvice: (organisationId: string, salonId: string, data: AdviceCreateRequest, params: RequestParams = {}) =>
      this.request<Advice, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/advices`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advices
     * @name GetAdviceGroups
     * @request GET:/organisations/{organisationId}/salons/{salonId}/advices/advice-groups
     * @secure
     */
    getAdviceGroups: (organisationId: string, salonId: string, params: RequestParams = {}) =>
      this.request<AdviceGroup[], any>({
        path: `/organisations/${organisationId}/salons/${salonId}/advices/advice-groups`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advices
     * @name CreateAdviceGroup
     * @request POST:/organisations/{organisationId}/salons/{salonId}/advices/advice-groups
     * @secure
     */
    createAdviceGroup: (organisationId: string, salonId: string, data: AdviceGroupCreateRequest, params: RequestParams = {}) =>
      this.request<AdviceGroup, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/advices/advice-groups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advices
     * @name GetAdviceConditions
     * @request GET:/organisations/{organisationId}/salons/{salonId}/advices/advice-conditions
     * @secure
     */
    getAdviceConditions: (organisationId: string, salonId: string, params: RequestParams = {}) =>
      this.request<AdviceCondition[], any>({
        path: `/organisations/${organisationId}/salons/${salonId}/advices/advice-conditions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advices
     * @name CreateAdviceCondition
     * @request POST:/organisations/{organisationId}/salons/{salonId}/advices/advice-conditions
     * @secure
     */
    createAdviceCondition: (
      organisationId: string,
      salonId: string,
      data: AdviceConditionCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<AdviceCondition, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/advices/advice-conditions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advices
     * @name ReorderAdvices
     * @request PUT:/organisations/{organisationId}/salons/{salonId}/advices/order
     * @secure
     */
    reorderAdvices: (organisationId: string, salonId: string, data: ReorderRequest, params: RequestParams = {}) =>
      this.request<Advice[], any>({
        path: `/organisations/${organisationId}/salons/${salonId}/advices/order`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advices
     * @name UpdateAdvice
     * @request PUT:/organisations/{organisationId}/salons/{salonId}/advices/{adviceId}
     * @secure
     */
    updateAdvice: (
      organisationId: string,
      salonId: string,
      adviceId: string,
      data: AdviceUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<Advice, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/advices/${adviceId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advices
     * @name UpdateAdviceGroup
     * @request PUT:/organisations/{organisationId}/salons/{salonId}/advices/advice-groups/{adviceGroupId}
     * @secure
     */
    updateAdviceGroup: (
      organisationId: string,
      salonId: string,
      adviceGroupId: string,
      data: AdviceGroupUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<AdviceGroup, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/advices/advice-groups/${adviceGroupId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advices
     * @name DeleteAdviceGroup
     * @request DELETE:/organisations/{organisationId}/salons/{salonId}/advices/advice-groups/{adviceGroupId}
     * @secure
     */
    deleteAdviceGroup: (organisationId: string, salonId: string, adviceGroupId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/advices/advice-groups/${adviceGroupId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advices
     * @name UpdateAdviceCondition
     * @request PUT:/organisations/{organisationId}/salons/{salonId}/advices/advice-conditions/{adviceConditionId}
     * @secure
     */
    updateAdviceCondition: (
      organisationId: string,
      salonId: string,
      adviceConditionId: string,
      data: AdviceConditionUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<AdviceCondition, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/advices/advice-conditions/${adviceConditionId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advices
     * @name DeleteAdviceCondition
     * @request DELETE:/organisations/{organisationId}/salons/{salonId}/advices/advice-conditions/{adviceConditionId}
     * @secure
     */
    deleteAdviceCondition: (organisationId: string, salonId: string, adviceConditionId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organisations/${organisationId}/salons/${salonId}/advices/advice-conditions/${adviceConditionId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
}

import * as Sentry from '@sentry/react';
import { API_URL, ENVIRONMENT } from 'common/app-constants';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';

Sentry.init({
  dsn: 'https://f7a279f5cd320c665bbcfb577fa329b0@o4506514354077696.ingest.sentry.io/4506514357092352',
  release: (window as { APP_VERSION?: string }).APP_VERSION,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracePropagationTargets: ['localhost', API_URL],
  environment: ENVIRONMENT,
  enabled: ENVIRONMENT !== 'local',
  tracesSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
